.frame-type-wvier_cta {
    position: relative;

    .cta-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            position: relative;
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .headline {
        font-size: $cta-headline-font-size;
        font-family: $headings-font-family;
        color: $white;
        line-height: $headings-line-height;
        margin-bottom: 2.625rem;
        display: block;
    }

    @include media-breakpoint-up(xl) {
        .headline {
            font-size: $cta-headline-font-size-xl;
        }
    }

    .cta-caption {
        position: relative;
        display: flex;
        height: 100%;
        padding-top: 5.375rem;
        padding-bottom: 3.875rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 1;

        @include media-breakpoint-up(xl) {
            padding-top: 10rem;
            padding-bottom: 8.5rem;
        }
    }

    .cta-buttons {
        .btn {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            margin-bottom: 1.5rem;
        }

    }
}
